<template>
  <div :style="btnStyle" class="btnClass" :class="{ btndown : selectedBtn }" draggable="true" @dragstart="drag($event)">
    <div :style="btncontent">
      <div v-if="showLang!='2'" class="textspan justify-center" :style="texeBold">
        {{ Name1 }}
      </div>
      <div v-if="showLang!='1'" class="textspan justify-center" :style="texeBold">
        {{ Name2 }}
      </div>
      <!-- <i class="el-icon-lock mustHave" v-if="mustHave"></i> -->
      <i class="el-icon-star-on recommend" v-if="recommend"></i>
      <i :style="myiconstyle" class="el-icon-remove-outline" @click.stop.prevent="removeButton($event)"></i>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MenuBtn',
    props: {
      selectedBtn: {
        type: Boolean,
        required: false,
        default: false,
      },
      showLang: {
        type: String,
        required: true,
        default: 'B',
      },
      Name1: {
        type: String,
        required: true,
        default: '',
      },
      Name2: {
        type: String,
        required: false,
        default: '',
      },
      BtnColor: {
        type: String,
        required: false,
        default: '',
      },
      fontSize: {
        type: Number,
        required: false,
        default: 14,
      },
      fontWeight: {
        type: Number,
        required: false,
        default: 400,
      },
      fontColor: {
        type: String,
        required: false,
        default: '#000000',
      },
      mywidth: {
        type: Number,
        required: false,
        default: 100,
      },
      myheight: {
        type: Number,
        required: false,
        default: 100,
      },
      // mustHave: {
      //   type: Boolean,
      //   required: false,
      //   default: false,
      // },
      recommend: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        btnStyle: {
          backgroundColor:'#cad1d7',
          width:'200px',
          height:'100px',
        },
        myiconstyle: {
          fontSize: '1rem',
          position: 'absolute',
          top: '2px',
          right: '4px',
          color: 'red',
        },
        texeBold: {
          fontSize:'14px',
          fontWeight: 400,
          color: '#000000',
        },
        btncontent: {
          paddingTop: '10px',
          textAlign: 'center',
          width:'100%',
          height: '100%',
          overflow: 'hidden',
        }
      };
    },
    methods: {
      drag(evt) {
        this.$emit('dragstart', evt);
      },
      removeButton(ev) {
        ev.preventDefault();
        this.$emit('removeButton', ev);
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          ;
        }
      },
    },
    mounted() {
      var ctop = parseInt((this.myheight - 48) / 2);
      this.btncontent.paddingTop = ctop + 'px';

      this.btnStyle.width = this.mywidth + 'px';
      this.btnStyle.height = this.myheight + 'px';
      this.btnStyle.backgroundColor = this.BtnColor;
      this.texeBold.fontSize = this.fontSize + 'px';
      this.texeBold.fontWeight = this.fontWeight;
      this.texeBold.color = this.fontColor;
      document.addEventListener('click', this.handleClickOutside);
      if (this.myicon == 'C') {
        this.myiconstyle.border = '2px solid';
        this.myiconstyle.borderColor = 'pink green yellow blue';
      }
      // console.log("MenuBtn mounted", this.Name1, this.selectedBtn);
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .iconc {
    font-size: 10px;
    position: absolute;
    top: 2px;
    right: 4px;
    color: red;
    border: 1px solid;
    border-color: rgb(255, 251, 0) green blue pink;
  }
  .table-remove {
    font-size: 10px;
    position: absolute;
    top: 2px;
    right: 4px;
    color: red;
  }
  .btnClass {
    line-height: 1.5rem;
    border: 1px solid;
    border-radius: 5px;
    border-style: outset;
    display: inline-block;
    position: relative;
  }
  .btndown {
    border-top: 3px solid;
    border-right: 3px solid;
    border-style: inset;
  }
  .mustHave {
    font-size: 1rem;
    position: absolute;
    top: 2px;
    left: 4px;
    color: red;
  }
  .recommend {
    font-size: 1rem;
    position: absolute;
    top: 2px;
    left: 4px;
    color: red;
  }

  .blink_me {
    animation: blinker 2s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }
</style>